import React from "react";
import { Row, Col } from "reactstrap";
import {
  BrowserView,
  MobileView,
  isTablet,
  // isBrowser,
  // isMobile,
} from "react-device-detect";

import name_logo from "../../../images/EnikoFodor1.png";
import "../components/mobile/Foot.css";

const foot = () => {
  return (
    <>
      <hr className="foot-hr" />
      <Row className="photo-write-row mt-4 mb-n3">
        <Col xl="4"></Col>
        <Col xl="4" className="bio-content">
          <div className="">
            <img src={name_logo} alt="name logo" width="162px" />
          </div>
        </Col>

        <Col xl="4"></Col>
      </Row>
      <div className="copy-right">
        {!isTablet && (
          <BrowserView>
            © 2025 All Rights Reserved. <span>ENIKO@ENIKOFODOR.COM</span>
          </BrowserView>
        )}
        <MobileView>
          <div className="mobile-foot">
            <p>© 2025 All Rights Reserved.</p>
            <p>ENIKO@ENIKOFODOR.COM</p>
          </div>
        </MobileView>
        {/* © 2021 All Rights Reserved. <span>ADITYO@ADITYOPRAKASH.COM</span> */}
      </div>
    </>
  );
};

export default foot;
